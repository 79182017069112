<template>
	<div>
		<b-row>
			<b-col cols="12">
				<b-form
					@submit.prevent="preparePayload"
					@keydown="clearError($event.target.name)"
					@change="clearError($event.target.name)">
					<b-row>
						<b-col
							v-if="isCorporateCountryAllowed"
							cols="12"
							sm="12"
							md="4"
							lg="4">
							<b-form-group class="form-group">
								<label
									for="message_type"
									class="label">{{ translate('type') }}
									<span class="text-danger">*</span></label>
								<select
									id="message_type"
									v-model="messageType"
									name="message_type"
									:class="hasError('message_type') ? 'is-invalid' : ''"
									class="form-control"
									@change="cleanMessageErrors()">
									<option
										v-for="type in types"
										:key="type"
										selected
										:value="type">
										{{ translate(type) }}
									</option>
								</select>
								<b-form-text id="message_type">
									{{ translate('allowed_countries') }}
								</b-form-text>
							</b-form-group>
						</b-col>
						<b-col
							v-if="!isCorporateWithCountry"
							cols="12"
							sm="12"
							md="6"
							lg="6">
							<b-form-group id="formSelector">
								<label
									for="country_selector"
									class="label">
									{{ translate('country_selector') }}
									<span class="text-danger">*</span>
								</label>
								<multiselect
									id="countries"
									v-model="selector"
									:errors="errors.errors['countries']"
									:options="countries"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('country_placeholder')"
									:class="hasError('filters') ? 'is-invalid' : ''"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="countries"
									@select="clearError('filters')"
									@remove="clearError('filters')">
									<template
										slot="selection"
										slot-scope="{ values, search, isOpen }">
										<span
											v-if="values.length && !isOpen"
											class="multiselect__single">
											{{ translate('options_selected', { count: values.length }) }}
										</span>
									</template>
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
								<template v-if="hasError('filters')">
									<span
										v-for="error in errors.errors['filters']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
								<div
									v-if="selector.length > 0"
									role="alert"
									class="a mb-3 mt-2 p-1 px-3 text-left text-small countries-alert">
									{{ getCountriesNames(selector) }}
								</div>
							</b-form-group>
						</b-col>
						<b-col
							v-if="!isCorporateWithCountry"
							cols="12"
							sm="12"
							md="2"
							lg="2"
							class="mx-auto d-flex justify-content-center align-items-center">
							<p>
								<b>{{ translate('selected_countries', {recipients: smsRecipients,}) }}</b>
							</p>
						</b-col>
					</b-row>
					<b-row
						v-if="messageType == 'mms'">
						<b-col>
							<b-form-group
								:errors="errors['image']"
								label-for="image">
								<slot name="label">
									<label>
										{{ translate('image') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<b-form-file
									id="image"
									ref="image"
									:placeholder="translate('no_file')"
									:browse-text="translate('browse')"
									:class="hasError('image') ? 'is-invalid' : ''"
									class="text-truncate"
									name="image"
									type="file"
									@change="clearError('image')" />
								<b-form-text id="image">
									{{ translate('max', {size: '10'}) }} {{ translate('allowed_ext', {ext: imgExt }) }}
								</b-form-text>
								<template v-if="hasError('image')">
									<span
										v-for="error in errors.errors['image']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-form-group
						id="textArea"
						:errors="errors.errors['message']">
						<label
							for="message"
							class="label">
							{{ translate('message') }}
							<span
								v-if="messageType != 'mms'"
								class="text-danger">*</span>
						</label>
						<b-form-textarea
							id="message"
							v-model="message"
							maxlength="160"
							:placeholder="translate('message_placeholder')"
							:class="hasError('message') ? 'is-invalid' : ''"
							name="message"
							no-resize
							@dataChanged="message = $event" />
						<template v-if="hasError('message')">
							<span
								v-for="error in errors.errors['message']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-form-group>
					<b-form-group
						id="passwordInput"
						:errors="errors.errors['password']">
						<label
							for="password"
							class="label">
							{{ translate('password') }}
							<span class="text-danger">*</span>
						</label>
						<input-password
							id="password"
							v-model="password"
							:placeholder="translate('password')"
							:input-class="`${hasError('password') ? 'is-invalid' : ''} form-control`"
							toggle-class="form-control"
							name="password" />
						<template v-if="hasError('password')">
							<span
								v-for="error in errors.errors['password']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-form-group>
					<b-button
						:disabled="Object.keys(errors.errors).length > 0 || loading"
						class="float-right"
						variant="primary"
						type="submit">
						<template v-if="loading">
							<i class="fa fa-fw fa-spinner fa-pulse" />
							{{ translate('processing') }}
						</template>
						<template v-else>
							{{ translate('send') }}
						</template>
					</b-button>
				</b-form>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import InputPassword from '@/components/InputPassword';
import { corporate, admin } from '@/settings/Roles';
import { MESSAGE_TYPES, IMAGES_EXT as imgExt, ALLOWED_MMS_COUNTRIES as allowedCountries } from '@/settings/Sms';
import {
	Sms as messages, Countries, Common,
} from '@/translations';
import Country from '@/util/Country';
import Sms from '@/util/Sms';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	name: 'SendSms',
	messages: [messages, Countries, Common],
	components: { InputPassword, Multiselect },
	data() {
		return {
			sms: new Sms(),
			country: new Country(),
			countries: [],
			countriesOnly: [],
			selector: [],
			messageType: 'sms',
			message: '',
			password: '',
			imgExt,
			allowedCountries,
			alert: new this.$Alert(),
			smsRecipients: 0,
			corporate,
			admin,
			types: MESSAGE_TYPES,
		};
	},
	computed: {
		errors() {
			return this.sms.data.errors;
		},
		loading() {
			return !!this.sms.data.loading;
		},
		isCorporateWithCountry() {
			return !!(this.corporate.includes(this.$user.details().type) && this.$user.details().country);
		},
		isCorporateCountryAllowed() {
			return (admin.includes(this.$user.details().type) && (this.$user.details().country === undefined || allowedCountries.includes(this.$user.details().country.iso_code_2)));
		},
	},
	watch: {
		selector() {
			const select = this.selector;
			if (select.length) {
				// no need to reassign 'select' if it's already equal to all of the countries
				if (this.handleSelectAll(select) === true && select !== this.countriesOnly) {
					this.selector = this.countriesOnly;
				}
				const countryFilter = { countries: this.getCountriesIso(select) };
				const options = { filters: JSON.stringify(countryFilter) };
				this.sms.getSmsRecipients(options).then((response) => {
					const validNumbers = response.response.valid_numbers;
					this.smsRecipients = validNumbers;
				});
			} else this.smsRecipients = 0;
		},
		language() {
			try {
				this.initializeMultiselect();
				if (this.selector.length > 0) {
					setTimeout(() => {
						this.selector = this.selector.map((item) => ({
							value: item.value,
							text: this.translate(item.value.toLowerCase()),
						}));
					}, 300);
				}
				return this.selector;
			} catch (error) {
				return this.selector;
			}
		},
	},
	mounted() {
		this.initializeMultiselect();
	},
	methods: {
		initializeMultiselect() {
			this.country.getCountries().then((response) => {
				const countryCode = response.map((item) => item.attributes);
				this.countries = countryCode.map((item) => ({
					value: item.code,
					text: this.translate(item.code.toLowerCase()),
				}));
				this.countriesOnly = JSON.parse(JSON.stringify(this.countries));
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			});
		},
		clearError(field) {
			if (field) {
				delete this.sms.data.errors.errors[field];
				this.sms.data.errors.errors = {

					...this.errors.errors,
				};
			}
		},
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		handleSelectAll(select) {
			// 'this.countries' array can contain 'Select all option' object
			// 'this.countriesOnly' does not change and it never contains 'Select all option' object, only countries
			let allSelected = false;
			const selectedCountries = select.map((country) => country.value);
			const numberOfCountries = this.countriesOnly.length;
			const selectAllExists = (this.countries[0].value === 'ALL');
			// If:  1) 'select all' option is chosen		OR		2) if you chose all countries manually
			// delete 'select all' option (as all countries are already chosen)
			if (selectedCountries.includes('ALL') || selectedCountries.length === numberOfCountries) {
				if (selectAllExists) {
					this.countries.shift();
				}
				allSelected = true;
			}
			// If 1) 'selected all' option is deleted		2) not all countries are selected
			// then insert 'select all' into the dropdown options.
			if ((!selectAllExists) && (selectedCountries.length !== numberOfCountries)) {
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			}
			return allSelected;
		},
		getCountriesIso(select) {
			const selectedIsoCodes = select.map((countryAttributes) => {
				if (countryAttributes.value === 'ALL') {
					return this.countries.map((country) => country.value);
				}
				return countryAttributes.value;
			}).toString();
			return selectedIsoCodes;
		},
		getCountriesNames(select) {
			const selectedNames = select.map((countryAttributes) => {
				if (countryAttributes.value === 'ALL') {
					return this.countries.map((country) => country.text);
				}
				return countryAttributes.text;
			}).join(', ');
			return selectedNames;
		},
		clearFields() {
			this.selector = [];
			this.message = '';
			this.password = '';
			if (this.countries[0].value !== 'ALL') {
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			}
		},
		cleanMessageErrors() {
			this.clearError('message');
			this.clearError('image');
			this.clearError('filters');
		},
		preparePayload() {
			const formData = new FormData();
			if (this.messageType === 'mms' && typeof this.$refs.image !== 'undefined') {
				const [File] = this.$refs.image.$refs.input.files;
				formData.set('image', File);
			}
			const message = this.message.toString();
			const countryFilter = { countries: this.getCountriesIso(this.selector) };
			if (this.isCorporateWithCountry) countryFilter.countries = this.$user.details().country.iso_code_2;
			const password = this.password.toString();
			formData.set('type', this.messageType);
			formData.set('message', message);
			formData.set('filters', JSON.stringify(countryFilter));
			formData.set('password', password);

			this.sendSms(formData);
		},
		sendSms(formData) {
			this.sms.data.loading = true;
			this.sms.createSms(formData).then(() => {
				this.alert.toast('success', this.translate('message_sent'));
				this.clearFields();
				this.$router.push({ name: 'SmsGrid' });
			}).catch((errors) => {
				this.data = formData;
				this.sms.data.errors.errors = errors.errors;
			}).finally(() => {
				this.sms.data.loading = false;
			});
		},
	},
};
</script>
<style scoped>
	.countries-alert {
		background-color: rgb(249 167 65 / 20%);
		border-color: #73818f;
		color: #7d6d6d;
		border-radius: 5px;
	}
</style>
